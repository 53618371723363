<template>
  <v-main>
      <v-container>
          <component-title title="cart_details_label_checkout" v-if="showTitle"/>
          <checkout-component @success="showTitle = false"/>
      </v-container>
  </v-main>
</template>

<script>
import checkoutComponent from '../components/Checkout/checkoutComponent.vue'
import ComponentTitle from '../components/UI/ComponentTitle.vue'
export default {
    components: {
        checkoutComponent,
        ComponentTitle
    },
    data: () => ({
        showTitle: true,
    })

}
</script>

<style>

</style>